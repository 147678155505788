// 字段 AccessKey
export function getShowAccessKey (platId, openApiStatus, isDebug, isAutoCreate, formquery) {
  formquery && (isDebug = formquery.isDebug)
  formquery && (openApiStatus = formquery.openApiStatus)
  formquery && (isAutoCreate = formquery.isAutoCreate)
  const p = +platId
  const allowArr = [1027, 1036, 1043, 1043, 1044, 1059, 1037, 1064, 1068, 1031, 1061, 1083, 1087, 1097, 1098, 1105, 1137]
  const otherArr = [24, 25, 27, 1, 3, 7, 6, 8, 11, 17, 989, 1007, 995, 1014, 19, 1030, 310, 9, 305]
  if (otherArr.includes(p) && openApiStatus) {
    return true
  } else if (otherArr.includes(p) && isAutoCreate) {
    return true
  } else if (allowArr.includes(p)) {
    return true
  } else if (+isDebug === 1 && (p === 1026 || p === 1028 || p === 1032 || p === 1034)) {
    // 旺脉 wave 舜飞 时代
    return true
  } else {
    return false
  }
}

export function getAccessKeyLabel (platId) {
  const p = +platId
  const labelList = [
    {
      label: 'RoleId',
      id: 1
    },
    {
      label: 'Access-Key',
      id: 3
    },
    {
      label: 'Access-Key',
      id: 7
    },
    {
      label: 'Access-Key',
      id: 6
    },
    {
      label: 'skey',
      id: 11
    },
    {
      label: 'Access-Key',
      id: 17
    },
    {
      label: 'RoleId',
      id: 8
    },
    {
      label: '登录联盟系统账户名',
      id: 19
    },
    {
      label: '授权码',
      id: 24
    },
    {
      label: 'accessKeySecret',
      id: 25
    },
    {
      label: 'token',
      id: 27
    },
    {
      label: '秘钥',
      id: 1007
    },
    {
      label: '私钥Base64',
      id: 1044
    },
    {
      label: '账户名',
      id: 19
    },
    {
      label: 'key',
      id: 1032
    },
    {
      label: 'key',
      id: 1034
    },
    {
      label: 'e_key',
      id: 989
    },
    {
      label: 'i_key',
      id: 1061
    },
    {
      label: 'encriyption_token',
      id: 1027
    },
    {
      label: 'key',
      id: 1026
    },
    {
      label: 'token',
      id: 995
    },
    {
      label: 'salt',
      id: 1014
    },
    {
      label: 'APP_KEY',
      id: 1030
    },
    {
      label: 'APP_KEY',
      id: 1036
    },
    {
      label: 'media',
      id: 1031
    },
    {
      label: '请求地址',
      id: 1028
    },
    {
      label: 'ikey',
      id: 1059
    },
    {
      label: 'ikey',
      id: 1064
    },
    {
      label: 'ikey',
      id: 1098
    },

    {
      label: '私钥',
      id: 1043
    },
    {
      label: 'token',
      id: 1037
    },
    {
      label: 'appKey',
      id: 1068
    },
    {
      label: 'ekey',
      id: 1087
    },
    {
      label: 'roleCode',
      id: 1083
    },
    {
      label: 'API KEY',
      id: 310
    },
    {
      label: 'Publisher Key',
      id: 9
    },
    {
      label: 'Report Key',
      id: 305
    },
    {
      label: 'ekey',
      id: 1097
    },
    {
      label: 'ikey',
      id: 1105
    },
    {
      label: 'ikey',
      id: 1137
    }
  ]
  return labelList.find((item) => item.id === p).label
}
// 字段 secretKey

export function getShowSecretKey (platId, openApiStatus, isAutoCreate, isDebug) {
  const p = +platId
  const arr = [
    990, 989, 1007, 1027, 1028, 1030, 1012, 1023, 1036, 1044, 1009, 1046, 1056, 1057, 1058, 1060, 1059, 1064, 1065,
    1067, 1068, 1066, 1073, 1061, 1049, 1080, 1081, 1086, 1087, 1045, 1091, 1097, 1100, 1098, 1105, 1114, 1115, 1119,
    1122, 1126, 1129, 1133, 1134, 1136, 1137
  ]
  if (arr.includes(p)) {
    return true
  } else if (
    openApiStatus &&
    (p === 1 ||
      p === 2 ||
      p === 3 ||
      p === 7 ||
      p === 8 ||
      p === 10 ||
      p === 11 ||
      p === 12 ||
      p === 13 ||
      p === 14 ||
      p === 19 ||
      p === 1002 ||
      p === 991 ||
      p === 305 ||
      p === 310 ||
      p === 9 ||
      p === 995)
  ) {
    // 穿山甲 优量汇 快手 Sigmob GroMore 趣盟 Mintegral 米盟 华为 OPPO VIVO HUAWEI API, OPPO API, 科大讯飞
    return true
  } else if (isAutoCreate && (p === 1 || p === 2 || p === 3)) {
    // 穿山甲 优量汇 快手
    return true
  } else if (+isDebug === 0 && (p === 1026 || p === 1032 || p === 1034)) {
    // wave 舜飞 时代
    return true
  } else {
    return false
  }
}

export function getSecretLabel (platId) {
  const p = +platId
  const labelList = [
    { label: 'client_secret', id: 13 },
    { label: 'client_secret', id: 991 },
    { label: 'client_secret', id: 1012 },
    { label: 'api_Key', id: 1002 },
    { label: 'api_Key', id: 1081 },
    { label: 'appkey', id: 14 },
    { label: 'appkey', id: 1067 },
    { label: 'appSecret', id: 12 },
    { label: 'i_key', id: 989 },
    { label: 'e_key', id: 1061 },
    { label: '密钥', id: 11 },
    { label: '密钥', id: 1023 },
    { label: '密钥', id: 1009 },
    { label: '密钥', id: 1046 },
    { label: '密钥', id: 1056 },
    { label: '密钥', id: 1057 },
    { label: '密钥', id: 1058 },
    { label: '密钥', id: 1060 },
    { label: '密钥', id: 1068 },
    { label: '密钥', id: 1066 },
    { label: '密钥', id: 1073 },
    { label: '密钥', id: 1049 },
    { label: '密钥', id: 1080 },
    { label: '密钥', id: 1086 },
    { label: '密钥', id: 1100 },
    { label: '密钥', id: 1122 },
    { label: '密钥', id: 1126 },
    { label: '密钥', id: 1129 },
    { label: '密钥', id: 1133 },
    { label: '密钥', id: 1134 },
    { label: '密钥', id: 1136 },
    { label: 'ekey', id: 1137 },
    { label: 'token', id: 1007 },
    { label: 'Integrity_token', id: 1027 },
    { label: 'Integrity_token', id: 1065 },
    { label: 'key', id: 1026 },
    { label: '登录邮箱', id: 995 },
    { label: 'Key', id: 1028 },
    { label: 'ekey', id: 1059 },
    { label: 'ekey', id: 1098 },
    { label: 'ekey', id: 1064 },
    { label: 'ekey', id: 1105 },
    { label: 'ekey', id: 1114 },
    { label: 'ekey', id: 1115 },
    { label: 'ekey', id: 1119 },
    { label: 'ekey', id: 1015 },
    { label: '私钥SecretKey', id: 1044 },
    { label: 'APP_SECRET', id: 1030 },
    { label: 'APP_SECRET', id: 1036 },
    { label: 'key', id: 1032 },
    { label: 'key', id: 1045 },
    { label: 'iv', id: 1091 },
    { label: 'ikey', id: 1087 },
    { label: 'ikey', id: 1097 },
    { label: 'SDK key', id: 305 },
    { label: '密钥', id: 310 },
    { label: 'APP key', id: 9 },
    { label: 'key', id: 1034 }
  ]
  const item = labelList.find((item) => item.id === p)
  return item ? item.label : 'Security-Key'
}
// 字段 accountId
export function getUserIdLabel (platId) {
  const p = +platId
  const labelList = [
    { label: '账户ID', id: 1 },
    { label: '账户ID', id: 2 },
    { label: '账户ID', id: 8 },
    { label: '账户ID', id: 3 },
    { label: '账户ID', id: 990 },
    { label: '账号', id: 24 },
    { label: 'accessKeyId', id: 25 },
    { label: '账号', id: 27 },
    { label: 'client_id', id: 13 },
    { label: 'client_id', id: 1012 },
    { label: 'client_id', id: 991 },
    { label: 'user_id', id: 14 },
    { label: 'user_id', id: 1002 },
    { label: 'user_id', id: 1083 },
    { label: 'devid', id: 12 },
    { label: 'account_id', id: 1027 },
    { label: '媒体ID', id: 1043 },
    { label: '媒体ID', id: 1030 },
    { label: '媒体ID', id: 1057 },
    { label: '媒体ID', id: 1069 },
    { label: '媒体ID', id: 1061 },
    { label: '媒体ID', id: 1095 },
    { label: '唯一标识', id: 1038 },
    { label: 'adxid', id: 1056 },
    { label: 'token', id: 1058 },
    { label: 'sid', id: 1063 },
    { label: 'vendor_id', id: 1062 },
    { label: 'appid', id: 1064 },
    { label: 'source_id', id: 1066 },
    { label: 'adxName', id: 1006 },
    { label: 'access_sign', id: 1077 }
  ]
  return labelList.find((item) => item.id === p)?.label
}
export function getshowUserId (platId, openApiStatus, isAutoCreate, form) {
  form && (openApiStatus = form.openApiStatus)
  form && (isAutoCreate = form.isAutoCreate)
  const p = +platId
  const allowArr = [
    3, 1027, 1012, 1038, 1043, 1056, 1057, 1058, 1063, 1062, 1064, 1066, 1069, 1061, 1077, 1083, 1006, 1095
  ]
  const otherArr = [24, 25, 27, 1, 2, 8, 12, 13, 14, 990, 1002, 991, 1030]
  if (p === 3 && !openApiStatus && !isAutoCreate) {
    return false
  } else if (openApiStatus && otherArr.includes(p)) {
    return true
  } else if (isAutoCreate && otherArr.includes(p)) {
    return true
  } else if (allowArr.includes(p)) {
    return true
  } else {
    return false
  }
}

// 不同广告平台显示不同的广告源名称
export function platPlaceLabel (id, formquery) {
  const isGromore = formquery && formquery.isGromore
  id = +id
  if (!id) {
    return '广告源ID'
  } else if (id === 1 && +isGromore) {
    return 'GM广告位ID'
  } else if (id === 1 || id === 6) {
    return '代码位ID'
  } else if (id === 2 || id === 3) {
    return '广告位ID'
  } else if (id === 7) {
    return '广告单元ID'
  } else if (id === 11) {
    return '广告单元ID'
  } else if (id === 998) {
    return 'posid'
  } else {
    return '广告位ID'
  }
}

// 字段 mid
export function midLabel (id) {
  return id === 993
    ? 'MID'
    : +id === 992
    ? 'ChannleID'
    : +id === 991
    ? '发布商ID'
    : +id === 996
    ? '渠道ID'
    : +id === 1000
    ? 'MID'
    : +id === 1011
    ? '渠道ID'
    : +id === 1001
    ? 'adx_id'
    : +id === 1008
    ? 'adx_id'
    : +id === 1006
    ? 'token'
    : +id === 1122
    ? 'publisher'
    : +id === 1130
    ? 'apiKey'
    : ''
}
export function showMid (id) {
  const allowArr = [993, 992, 991, 996, 1000, 1011, 1001, 1008, 1006, 1122, 1130]
  return allowArr.includes(+id)
}
export function midRequired (platId) {
  const p = +platId
  const notArr = [1006]
  if (notArr.includes(p)) {
    return false
  } else {
    return true
  }
}
// 字段 isAutoCreate
export function showIsAutoCreate (id) {
  const allowPlats = [1, 2, 3, 6]
  return allowPlats.includes(+id)
}
// 字段 openApi
export function showOpenApiStatus (currentPlat) {
  const allowArr = [990, 1002, 991, 1007, 995, 1014]
  return +currentPlat.accessType === 0 || allowArr.includes(+currentPlat.id)
}

// 字段 signKey
export function getSignKeyLabel (platId) {
  const p = +platId
  const labelList = [
    { label: '密钥', id: 991 },
    { label: '密钥', id: 1067 },
    { label: 'signKey', id: 1008 },
    { label: 'adx_secret', id: 1001 },
    { label: '请求地址', id: 1046 },
    { label: '请求地址', id: 1065 },
    { label: '请求地址', id: 1072 },
    { label: '请求地址', id: 1032 },
    { label: '请求地址', id: 1034 },
    { label: '请求地址', id: 1009 },
    { label: '竞价密钥', id: 1002 },
    { label: '测试地址', id: 1044 },
    { label: '测试地址', id: 1079 },
    { label: '测试地址', id: 1089 },
    { label: '测试地址', id: 1052 },
    { label: '测试地址', id: 1093 },
    { label: '测试地址', id: 1092 },
    { label: '测试地址', id: 1088 },
    { label: '测试地址', id: 1094 },
    { label: '测试地址', id: 1126 },
    { label: '测试地址', id: 1127 },
    { label: '测试地址', id: 1096 },
    { label: '密钥', id: 1091 },
    { label: '密钥', id: 1118 },
    { label: '密钥', id: 1119 },
    { label: '测试地址', id: 1045 },
    { label: '测试地址', id: 1090 },
    { label: '测试地址', id: 1101 },
    { label: '测试地址', id: 1116 },
    { label: '价格密钥', id: 1102 },
    { label: '价格密钥', id: 1103 },
    { label: '价格密钥', id: 1106 },
    { label: '价格密钥', id: 1107 },
    { label: '价格密钥', id: 1108 },
    { label: '价格密钥', id: 1109 },
    { label: '密钥', id: 1006 },
    { label: 'iv', id: 1105 },
    { label: 'sign', id: 1083 }
  ]
  const item = labelList.find((item) => item.id === p)
  return item ? item.label : 'signKey'
}

export function getShowSignKey (platId, isDebug) {
  const p = +platId
  const allowList = [1008, 991, 1001, 1002, 1012, 1039, 1067, 1083, 1006, 1089, 1091, 1105, 1118]
  const debugList = [
    1032, 1034, 1009, 1046, 1065, 1072, 1044, 1079, 1052, 1090, 1045, 1090, 1093, 1088, 1094, 1096, 1101, 1102, 1103,
    1106, 1107, 1108, 1109, 1116, 1120, 1121, 1126, 1127
  ]
  if (allowList.includes(p)) {
    return true
  } else if (+isDebug === 1 && debugList.includes(p)) {
    return true
  }
}

// 字段 heigh width
export function showHWIds (platId) {
  const id = +platId
  // 需要显示素材模板的广告平台
  const showPlatList = [
    997, 987, 989, 995, 998, 1000, 1007, 1008, 1009, 1011, 1014, 1015, 1020, 1025, 1026, 1027, 1028, 1031, 1032, 1034,
    1035, 1037, 1041, 1043, 1047, 1049, 1050, 1051, 1053, 994, 1056, 1057, 1058, 1060, 1063, 1064, 1065, 1066, 1067,
    992, 1075, 1076, 1080, 1081, 1086, 1091, 1093, 1095, 1102, 1084, 1118, 1119, 1122, 1123, 1126, 1127, 1128, 1129,
    1133, 1134, 1135, 1136, 1137
  ]
  return showPlatList.includes(id)
}

// 需要填应用ID
export function showAppIds (platId) {
  const id = +platId
  // 需要显示应用的广告平台
  const showPlatList = [
    990, 1002, 1012, 1013, 1021, 1029, 989, 995, 1014, 1015, 1020, 1022, 1025, 1026, 1034, 1035, 1036, 1037, 1038, 1041,
    1043, 1040, 1049, 1052, 994, 1056, 1057, 1063, 1066, 1067, 1068, 1069, 1008, 992, 1073, 1061, 1075, 1076, 1081,
    1083, 1089, 1090, 998, 1090, 1088, 1100, 1098, 1101, 1102, 1084, 1112, 1115, 1116, 1119, 1122, 1124, 1126, 1129,
    1134, 1136, 1137
  ]
  return showPlatList.includes(id)
}
export function getAppIdLabel (id) {
  const p = +id
  const labelList = [
    {
      id: 998,
      label: 'platformid'
    },
    {
      id: 994,
      label: 'platformid'
    }
  ]
  const item = labelList.find((item) => item.id === p)
  return item ? item.label : '应用ID'
}

// 需要填请求地址
export function showRequestUrl (platId, form) {
  const id = +platId
  const isDebug = +form.isDebug
  if (
    id === 989 ||
    id === 1006 ||
    id === 1091 ||
    id === 1100 ||
    id === 1104 ||
    id === 1105 ||
    id === 1114 ||
    id === 1115 ||
    id === 1015 ||
    id === 1053 ||
    id === 1116 ||
    id === 1122 ||
    id === 1124 ||
    id === 1058 ||
    id === 1129 ||
    id === 1128 ||
    id === 1130 ||
    id === 1134 ||
    id === 1135 ||
    id === 1136 ||
    id === 1137 ||
    id === 1133
  ) {
    return true
  } else if (
    isDebug === 0 &&
    (id === 1032 ||
      id === 1034 ||
      id === 1009 ||
      id === 1023 ||
      id === 1028 ||
      id === 1039 ||
      id === 1046 ||
      id === 1065 ||
      id === 1072 ||
      id === 1044 ||
      id === 1052 ||
      id === 1090 ||
      id === 1045 ||
      id === 1090 ||
      id === 1092 ||
      id === 1093 ||
      id === 1088 ||
      id === 1094 ||
      id === 1096 ||
      id === 1101 ||
      id === 1102 ||
      id === 1103 ||
      id === 1106 ||
      id === 1107 ||
      id === 1108 ||
      id === 1084 ||
      id === 1109 ||
      id === 1112 ||
      id === 1118 ||
      id === 1119 ||
      id === 1120 ||
      id === 1121 ||
      id === 1126 ||
      id === 1127 ||
      id === 1079)
  ) {
    return true
  } else if (isDebug === 1 && id === 1026) {
    return true
  } else {
    return false
  }
}

/*
 *
 *
 * @export 是否自渲染是否显示
 * @param {any} formquery 表单
 * @param {any} position 广告样式
 * @param {any} platId 广告平台ID
 * */
export function showIsAutoDrawing (formquery, position, platId) {
  const positionId = +position
  const pId = +platId
  const isAutoCreate = formquery.isAutoCreate
  const allowPositions1 = [1, 2, 3]
  const allowPlats1 = [1, 2, 3, 6, 36]
  if (allowPositions1.includes(positionId) && allowPlats1.includes(pId) && !isAutoCreate) {
    return true
  }
  const allowPositions2 = [1]
  const allowPlats2 = [300, 302, 301, 11, 303, 309, 307, 31, 36]
  if (allowPositions2.includes(positionId) && allowPlats2.includes(pId) && !isAutoCreate) {
    return true
  }
  const allowPositions3 = [3]
  const allowPlats3 = [31]
  if (allowPositions3.includes(positionId) && allowPlats3.includes(pId) && !isAutoCreate) {
    return true
  }
  const allowPositions4 = [2]
  const allowPlats4 = [15, 16, 24]
  if (allowPositions4.includes(positionId) && allowPlats4.includes(pId) && !isAutoCreate) {
    return true
  }
  return false
}

// 广告源appkey是否显示
export function showAppKey (platId) {
  const p = +platId
  const allowArr = [7, 11, 21, 9, 1040, 1006, 38]
  return allowArr.includes(p)
}

// 字段 key
export function showKey (platId) {
  const p = +platId
  const allowArr = [1061]
  return allowArr.includes(p)
}

export function getKeyLabel (platId) {
  const id = +platId
  const labelList = [
    {
      id: 1061,
      label: '请求类型'
    }
  ]
  return labelList.find((item) => item.id === id)?.label
}

// 字段 encryptType
export function showEncryptType (platId) {
  const p = +platId
  const allowArr = [1061, 1083]
  return allowArr.includes(p)
}

export function getEncryptTypeLabel (platId) {
  const id = +platId
  const labelList = [
    {
      id: 1061,
      label: '加密方式'
    },
    {
      id: 1083,
      label: '竞价漏斗回传'
    }
  ]
  return labelList.find((item) => item.id === id)?.label
}

// 字段 encryptParameter
export function showEncryptParameter (platId) {
  const p = +platId
  const allowArr = [1061, 1083]
  return allowArr.includes(p)
}

export function getEncryptParameterLabel (platId) {
  const id = +platId
  const labelList = [
    {
      id: 1061,
      label: '加密参数'
    },
    {
      id: 1083,
      label: '安装列表回传'
    }
  ]
  return labelList.find((item) => item.id === id)?.label
}

// 字段 codingType
export function showCodingType (platId) {
  const p = +platId
  const allowArr = [1061]
  return allowArr.includes(p)
}

export function getCodingTypeLabel (platId) {
  const id = +platId
  const labelList = [
    {
      id: 1061,
      label: '编码方式'
    }
  ]
  return labelList.find((item) => item.id === id)?.label
}

// 字段 keyId
export function showKeyId (platId) {
  const p = +platId
  const allowArr = [991]
  return allowArr.includes(p)
}

export function getKeyIdLabel (platId) {
  const id = +platId
  const labelList = [
    {
      id: 991,
      label: '密钥Id'
    }
  ]
  return labelList.find((item) => item.id === id)?.label
}

// 字段 callbackUrl
export function showCallbackUrl (platId) {
  const p = +platId
  const allowArr = []
  return allowArr.includes(p)
}

export function getCallbackUrlLabel (platId) {
  const id = +platId
  const labelList = [
    // {
    //   id: 1046,
    //   label: '回调地址'
    // }
  ]
  return labelList.find((item) => item.id === id)?.label
}

// slotId 字段
export function showSlotId (platId) {
  const p = +platId
  const allowArr = [1087, 1046, 1045, 1001, 998, 994, 1039, 1097]
  return allowArr.includes(p)
}

export function getSlotIdLabel (platId) {
  const id = +platId
  const labelList = [
    {
      id: 1087,
      label: '模板ID',
      placeholder: '模板ID，多个ID以英文逗号隔开'
    },
    {
      id: 1046,
      label: '模板ID',
      placeholder: '模板ID，多个ID以英文逗号隔开'
    },
    {
      id: 1045,
      label: '模板ID',
      placeholder: '模板ID，多个ID以英文逗号隔开'
    },
    {
      id: 1001,
      label: '播放形式ID',
      placeholder: '播放形式ID'
    },
    {
      id: 994,
      label: 'token',
      placeholder: 'token'
    },
    {
      id: 998,
      label: 'token',
      placeholder: 'token'
    },
    {
      id: 1039,
      label: '模板ID',
      placeholder: '模板ID，多个ID以英文逗号隔开'
    },
    {
      id: 1097,
      label: '模板ID',
      placeholder: '模板ID，多个ID以英文逗号隔开'
    }
  ]
  return labelList.find((item) => item.id === id)
}
